import React, { useState, useEffect, useCallback } from 'react';
import ProductQRCard from './ProductQR';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSignOutAlt, faCartShopping, faUserFriends, faSlidersH, faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function CustomerDashboard() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('None');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch('http://74.225.226.123:8080/api/GetAllProducts');
      if (!response.ok) throw new Error('Failed to fetch products');
      const data = await response.json();

      const sortedData = data.sort((a, b) => {
        const dateA = new Date(a.TimeStamp);
        const dateB = new Date(b.TimeStamp);
        return dateB - dateA;
      });

      setProducts(sortedData.map(product => ({
        id: product.ID,
        name: product.Name,
        price: product.Price,
        weight: product.Weight,
        type: product.ProdType,
        dietForm: product.DietForm,
        manufacturingDate: product.MD,
        expiryDate: product.ED,
        batchNo: product.BatchNo,
        image: product.ImageURL,
        cert: product.CertURL,
        timeStamp: product.TimeStamp
      })));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleReloadProductClick = async () => {
    setIsLoading(true);
    await fetchProducts();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setCurrentPage(1); // Reset to first page on sort change
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredProducts = products.filter(product => {
    if (filter !== 'All' && (filter === 'Dry' || filter === 'Wet') && product.dietForm !== filter) {
      return false;
    }
    if (filter !== 'All' && (filter !== 'Dry' && filter !== 'Wet') && product.type !== filter) {
      return false;
    }
    if (searchTerm && !product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    return true;
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    switch (sortBy) {
      case 'Weight':
        return a.weight - b.weight;
      case 'Price':
        return a.price - b.price;
      case 'MFG Date':
        return new Date(a.manufacturingDate) - new Date(b.manufacturingDate);
      default:
        return 0;
    }
  });

  // Pagination Logic
  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
  const paginatedProducts = sortedProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>RC | Customer Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <h2 className="text-center mb-4">
          <FontAwesomeIcon
            icon={faUserFriends}
            style={{ fontSize: '100px', marginBottom: '10px' }}
          />
          <br />
          Customer Dashboard
        </h2>
        <div className="d-flex flex-column">
          <button className="btn btn-danger w-100 mb-3" onClick={handleReloadProductClick}>
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} /> : <FontAwesomeIcon icon={faCartShopping} style={{ marginRight: '5px' }} />}
            Purchase
          </button>
          <button className="btn btn-secondary w-100" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
            Log Out
          </button>
        </div>
      </div>
      <div className="main-content">
        <div className="d-flex align-items-center justify-content-between mb-4 filter-title-container">
          <h3 className="mb-0">Purchase Products</h3>
          <div className="d-flex align-items-center">
            <div className="search-bar-container d-flex align-items-center ml-2">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-box"
              />
            </div>
            <div className="filter-container d-flex align-items-center mr-4">
              <span className="filter-text">Filter...</span>
              <select onChange={handleFilterChange} value={filter} className="filter-box">
                <option value="All">All</option>
                <option value="Dog Food">Dog Food</option>
                <option value="Cat Food">Cat Food</option>
                <option value="Dry">Dry</option>
                <option value="Wet">Wet</option>
              </select>
              <FontAwesomeIcon icon={faSlidersH} className="filter-icon" />
            </div>
            <div className="sort-container ms-3">
              <span className="sort-text">Sort...</span>
              <select onChange={handleSortChange} value={sortBy} className="sort-box">
                <option value="None">None</option>
                <option value="Weight">Weight</option>
                <option value="Price">Price</option>
                <option value="MFG Date">MFG Date</option>
              </select>
              <FontAwesomeIcon icon={faSort} className="sort-icon" />
            </div>
          </div>
        </div>
        <div className="product-list-container">
          {isLoading ? (
            <div className="text-center">
              <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>
          ) : (
            <>
              <div className="row">
                {paginatedProducts.map(product => (
                  <ProductQRCard key={product.id} product={product} />
                ))}
              </div>
              {/* <div className="pagination d-flex justify-content-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    className={`btn btn-outline-secondary mx-1 ${currentPage === index + 1 ? 'active' : ''}`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerDashboard;
