import React from 'react';
import ReactDOM from 'react-dom/client';
import './';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './component/loginForm';
import AdminDashboard from './component/AdminDashboard'; // Assuming this file is named AdminDashboard.js
import CustomerDashboard from './component/CustomerDashboard'; // Assuming this file is named CustomerDashboard.js

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/AdminDashboard" element={<AdminDashboard />} />
        <Route path="/CustomerDashboard" element={<CustomerDashboard />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();