import React, { useState, useEffect, useCallback } from 'react';
import ProductCard from './ProductCard';
import { Helmet } from 'react-helmet';
import InsertProductForm from './InsertProductForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus, faSignOutAlt, faUserCircle, faShoppingCart, faFilter, faSlidersH, faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function AdminDashboard() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('reload');
  const [noProductsMessage, setNoProductsMessage] = useState('');
  const [filter, setFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [sortBy, setSortBy] = useState('None');
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    setNoProductsMessage('');
    try {
      const response = await fetch('http://74.225.226.123:8080/api/GetAllProducts');
      if (!response.ok) throw new Error('Failed to fetch products');
      const data = await response.json();

      if (data.message === 'No products found') {
        setNoProductsMessage('No products found');
      } else {
        const sortedData = data.map(product => ({
          id: product.ID,
          name: product.Name,
          price: product.Price,
          weight: product.Weight,
          type: product.ProdType,
          dietForm: product.DietForm,
          manufacturingDate: product.MD,
          expiryDate: product.ED,
          batchNo: product.BatchNo,
          image: product.ImageURL,
          cert: product.CertURL,
          timeStamp: product.TimeStamp
        }));

        setProducts(sortedData);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'reload') {
      fetchProducts();
    }
  }, [activeTab, fetchProducts]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      const searchSuggestions = products
        .filter(product => product.name.toLowerCase().includes(value.toLowerCase()))
        .map(product => product.name);
      setSuggestions(searchSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Ensure the newest products appear first
  const sortedProducts = products
    .sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))
    .filter(product => {
      if (filter !== 'All') {
        if (filter === 'Dry' || filter === 'Wet') return product.dietForm === filter;
        return product.type === filter;
      }
      if (searchTerm) return product.name.toLowerCase().includes(searchTerm.toLowerCase());
      return true;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'Weight':
          return a.weight - b.weight;
        case 'Price':
          return a.price - b.price;
        case 'MFG Date':
          return new Date(a.manufacturingDate) - new Date(b.manufacturingDate);
        default:
          return 0;
      }
    });

  // Callback function to be called when a new product is added
  const handleProductAdded = () => {
    fetchProducts();
    setActiveTab('reload'); // Switch back to the reload tab if necessary
  };

  return (
    <div className="container-fluid">
      <Helmet>
        <title>RC | Admin Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <h2 className="text-center mb-4">
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{ fontSize: '100px', marginBottom: '10px' }}
          />
          <br />
          Admin Dashboard
        </h2>
        <ul className="nav flex-column">
          <li className="nav-item mb-3">
            <button className={`btn btn-warning w-100 ${activeTab === 'reload' ? 'active' : ''}`} onClick={() => handleTabClick('reload')}>
              {isLoading ? <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} /> : <FontAwesomeIcon icon={faShoppingCart} style={{ marginRight: '5px' }} />}
              List Products
            </button>
          </li>
          <li className="nav-item mb-3">
            <button className={`btn btn-danger w-100 ${activeTab === 'insert' ? 'active' : ''}`} onClick={() => handleTabClick('insert')}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Insert Product
            </button>
          </li>
          <li className="nav-item">
            <button className="btn btn-secondary w-100" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
              Log Out
            </button>
          </li>
        </ul>
      </div>
      <div className="main-content">
        {activeTab === 'insert' && (
          <div className="insert-tab">
            <InsertProductForm onProductAdded={handleProductAdded} />
          </div>
        )}
        {activeTab === 'reload' && (
          <div className="reload-tab">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h3 className="mb-0">Manage Inventory</h3>
              <div className="d-flex align-items-center">

                <div className="search-bar-container">
                  <input
                    type="text"
                    placeholder="Search products..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-box"
                  />
                  <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </div>
                <div className="filter-container">
                  <span className="filter-text">Filter...</span>
                  <select onChange={handleFilterChange} value={filter} className="filter-box">
                    <option value="All">All</option>
                    <option value="Dog Food">Dog Food</option>
                    <option value="Cat Food">Cat Food</option>
                    <option value="Dry">Dry</option>
                    <option value="Wet">Wet</option>
                  </select>
                  <FontAwesomeIcon icon={faSlidersH} className="filter-icon" />
                </div>
                <div className="sort-container ms-3">
                  <span className="sort-text">Sort...</span>
                  <select onChange={handleSortChange} value={sortBy} className="sort-box">
                    <option value="None">None</option>
                    <option value="Weight">Weight</option>
                    <option value="Price">Price</option>
                    <option value="MFG Date">MFG Date</option>
                  </select>
                  <FontAwesomeIcon icon={faSort} className="sort-icon" />
                </div>
              </div>
            </div>
            <div className="product-list-container">
              {isLoading ? (
                <div className="text-center">
                  <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
              ) : noProductsMessage ? (
                <div className="text-center text-danger">
                  <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{noProductsMessage}</p>
                </div>
              ) : (
                <div className="row">
                  {sortedProducts.map(product => (
                    <ProductCard key={product.id} product={product} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
