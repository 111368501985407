import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeight, faTag, faBarcode, faCalendarAlt, faDollarSign, faEdit, faTrashAlt, faBone, faChain } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Col, Row, Modal, Alert, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Styled components
const StyledCard = styled(Card)`
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  margin: 0;
  height: 500px; /* Set a fixed height for uniformity */

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 250px; /* Fixed height for uniformity */
  object-fit: cover;
  border-radius: 10px;
  padding: 10px; /* Add padding around the image */
`;

const ProductDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const DetailIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ActionButton = styled(Button)`
  background-color: white;
  color: #ff4d4d;
  border: 2px solid #ff4d4d;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;

  &:hover {
    background-color: #ff4d4d;
    color: white;
    border: 2px solid #ff4d4d;
  }
  &:focus {
    box-shadow: none;
  }
`;

const ButtonText = styled.span`
  margin-left: 5px;
`;

const CardTitle = styled(Card.Title)`
  color: red;
  font-weight: 600;
  font-size: 19px;
  text-align: left;
  margin-bottom: 15px;
  overflow-wrap: break-word;
`;

const CenteredModalTitle = styled(Modal.Title)`
  text-align: center;
  width: 100%;
`;

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TopAlert = styled(Alert)`
  margin-bottom: 20px;
`;

const ButtonCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const IDDisplayContainer = styled.div`
  word-break: break-all;
  white-space: pre-wrap;
`;

const SuccessMessage = styled.div`
  margin-bottom: 20px;
  color: green;
  font-weight: bold;
  text-align: center;
`;

const FormGroup = styled(Form.Group)`
  margin-top: 5px;
`;

const RedText = styled.span`
  color: red;
`;

const ProductCard = ({ product }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [confirmationText, setConfirmationText] = useState('');
  const [productId, setProductId] = useState('');
  const [validated, setValidated] = useState(false);
  const [noChangesMessage, setNoChangesMessage] = useState('');

  const handleDelete = (event) => {
    event.preventDefault();

    if (confirmationText !== 'DELETE') {
      setAlert({
        show: true,
        message: 'Please type "DELETE" to confirm.',
        variant: 'danger'
      });
      return;
    }

    fetch(`http://74.225.226.123:8080/api/DeleteProduct/${productId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.ok) {
          setSuccessMessage(`Product "${product.name}" deleted successfully.`);
          setAlert({ show: false, message: '', variant: '' });
          setTimeout(() => {
            setSuccessMessage('');
            setShowModal(false); // Close the modal
            window.location.reload(); // Reload the page
          }, 3000); // Delay to show success message
        } else {
          setFailureMessage('Invalid Product Id');
          setAlert({ show: false, message: '', variant: '' });
        }
      })
      .catch(error => {
        setFailureMessage('An error occurred: ' + error.message);
        setAlert({ show: false, message: '', variant: '' });
      });
  };

  const handleEdit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const updatedProduct = {
      name: event.target.name.value,
      price: event.target.price.value,
      weight: event.target.weight.value,
      dietForm: event.target.dietForm.value,
      batchNo: event.target.batchNo.value,
      type: event.target.type.value,
      md: event.target.md.value,
      ed: event.target.ed.value,
    };

    const noChanges = (
      updatedProduct.name === product.name &&
      updatedProduct.price === product.price.toString() &&
      updatedProduct.weight === product.weight.toString() &&
      updatedProduct.dietForm === product.dietForm &&
      updatedProduct.batchNo === product.batchNo &&
      updatedProduct.type === product.type &&
      updatedProduct.md === product.manufacturingDate.split('T')[0] &&
      updatedProduct.ed === product.expiryDate.split('T')[0]
    );

    if (noChanges) {
      setNoChangesMessage('No changes made.');
      return;
    }

    fetch(`http://74.225.226.123:8080/api/UpdateProduct/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedProduct)
    })
      .then(response => {
        if (response.ok) {
          setSuccessMessage(`Product "${product.name}" updated successfully.`);
          setNoChangesMessage('');
          setShowEditModal(true);

          // Use a new function to ensure modal closure before reload
          setTimeout(() => {
            setSuccessMessage(''); // Clear success message
            setShowEditModal(false); // Close the modal
            window.location.reload(); // Reload the page
          }, 3000); // Adjust delay as needed
        } else {
          setFailureMessage('Failed to update product.');
          setAlert({ show: false, message: '', variant: '' });
        }
      })
      .catch(error => {
        setFailureMessage('An error occurred: ' + error.message);
        setAlert({ show: false, message: '', variant: '' });
      });
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 mb-4">
      <StyledCard className="h-100 shadow">
        <Card.Body className="d-flex flex-column">
          <Row className="h-100">
            <Col xs={12} md={5} className="d-flex flex-column align-items-center">
              <CardImage src={product.image} alt={product.name} />
            </Col>
            <Col xs={12} md={7} className="d-flex flex-column justify-content-between">
              <CardTitle>{product.name}</CardTitle>
              <div className="product-details">
                <ProductDetail>
                  <DetailIcon icon={faDollarSign} />
                  <span className="detail-text">{product.price.toFixed(2)}</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faWeight} />
                  <span className="detail-text">{product.weight} Kg</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faTag} />
                  <span className="detail-text">{product.type}</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faBone} />
                  <span className="detail-text">{product.dietForm}</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faBarcode} />
                  <span className="detail-text">{product.batchNo}</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faCalendarAlt} />
                  <span className="detail-text">MFG-{new Date(product.manufacturingDate).toLocaleDateString()}</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faCalendarAlt} />
                  <span className="detail-text">EXP-{new Date(product.expiryDate).toLocaleDateString()}</span>
                </ProductDetail>
                <ProductDetail>
                  <DetailIcon icon={faChain} />
                  <IDDisplayContainer>{product.id}</IDDisplayContainer>
                </ProductDetail>
              </div>
              <ButtonContainer>
                <ActionButton onClick={() => {
                  setShowEditModal(true);
                  setProductId(product.id);
                  setSuccessMessage(''); // Clear the success message when opening the modal
                }}>
                  <FontAwesomeIcon icon={faEdit} />
                  <ButtonText>Edit</ButtonText>
                </ActionButton>
                <ActionButton onClick={() => {
                  setShowModal(true);
                  setProductId(product.id);
                }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <ButtonText>Delete</ButtonText>
                </ActionButton>
              </ButtonContainer>
            </Col>
          </Row>
        </Card.Body>
      </StyledCard>

      {/* Modal for Delete */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <CenteredModalTitle>Delete Product</CenteredModalTitle>
        </Modal.Header>
        <Modal.Body>
          {successMessage && <TopAlert variant="success">{successMessage}</TopAlert>}
          <h5>Are you sure you want to delete <RedText>{product.name}</RedText>?</h5>
          <Form onSubmit={handleDelete}>
            <Form.Group>
              {/* <Form.Label>Please type "DELETE" to confirm:</Form.Label> */}
              <Form.Control
                type="text"
                value={confirmationText}
                onChange={(e) => setConfirmationText(e.target.value)}
                placeholder='Type DELETE to delete product'
              />
            </Form.Group>
            <ButtonCenterContainer>
              <Button variant="danger" type="submit">
                Delete
              </Button>
            </ButtonCenterContainer>
            {alert.show && <Alert variant={alert.variant} className="mt-3">{alert.message}</Alert>}
            {/* {successMessage && <SuccessMessage className="mt-3">{successMessage}</SuccessMessage>} */}
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for Edit */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <CenteredModalTitle>Edit Product</CenteredModalTitle>
        </Modal.Header>
        <Modal.Body>
          {successMessage && <TopAlert variant="success">{successMessage}</TopAlert>}
          {noChangesMessage && <TopAlert variant="danger">{noChangesMessage}</TopAlert>}
          <Form onSubmit={handleEdit} noValidate validated={validated}>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" defaultValue={product.name} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="number" step="0.01" name="price" defaultValue={product.price} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Weight</Form.Label>
                  <Form.Control type="number" name="weight" defaultValue={product.weight} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Diet Form</Form.Label>
                  <Form.Control type="text" name="dietForm" defaultValue={product.dietForm} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Batch No</Form.Label>
                  <Form.Control type="text" name="batchNo" defaultValue={product.batchNo} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Type</Form.Label>
                  <Form.Control type="text" name="type" defaultValue={product.type} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Manufacturing Date</Form.Label>
                  <Form.Control type="date" name="md" defaultValue={product.manufacturingDate.split('T')[0]} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Form.Label>Expiry Date</Form.Label>
                  <Form.Control type="date" name="ed" defaultValue={product.expiryDate.split('T')[0]} />
                </FormGroup>
              </Col>
            </Row>
            <CenteredButtonContainer className='mt-3'>
              <Button variant="danger" type="submit">Save Changes</Button>
            </CenteredButtonContainer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>} */}
      {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
    </div>
  );
};

export default ProductCard;
