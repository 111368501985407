import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Card, Button, Modal, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartShopping, faWeight, faTag, faBarcode, faCalendarAlt, faDollarSign, faPlus, faMinus, faBone,
} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css";
import styled from 'styled-components';

// Styled components for buttons
const ActionButton = styled(Button)`
  background-color: white;
  color: #ff4d4d;
  border: 2px solid #ff4d4d;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;

  &:hover {
    background-color: #ff4d4d;
    color: white;
    border-color: #ff4d4d;
  }

  &:focus, &:active {
    box-shadow: none;
    border-color: #ff4d4d;
  }
`;

const CartButton = styled(ActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductQR = ({ product }) => {
  const defaultImage = 'https://via.placeholder.com/200';
  const productImage = product.image || defaultImage;
  const [cartCount, setCartCount] = useState(0);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleAddToCart = () => {
    setCartCount(cartCount + 1);
  };

  const handleRemoveFromCart = () => {
    if (cartCount > 0) {
      setCartCount(cartCount - 1);
    }
  };

  const handleQRCodeClick = () => {
    setShowQRCodeModal(true);
  };

  const handleCloseQRCodeModal = () => {
    setShowQRCodeModal(false);
  };

  const handleImageClick = () => {
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  return (
    <div className="col-md-4 mb-4">
      <Card className="h-100 shadow" style={{ height: '500px' }}>
        <Card.Body className="d-flex flex-column">
          <Row>
            <Col xs={12} md={5} className="d-flex flex-column align-items-center">
              <img
                src={productImage}
                alt={product.name}
                onClick={handleImageClick}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  padding: '10px'
                }}
              />
              <div
                className="qr-container mt-3"
                onClick={handleQRCodeClick}
                style={{ cursor: 'pointer' }}
              >
                <QRCode value={product.cert} size={105} level="H" includeMargin />
                <p className="text-muted mt-2" style={{ textAlign: 'center' }}>Click to Zoom</p>
              </div>
            </Col>
            <Col xs={12} md={7} className="d-flex flex-column justify-content-between">
              <Card.Title style={{ color: 'red', fontSize: '19px' }}>{product.name}</Card.Title>
              <div className="product-details">
                <ProductDetail icon={faDollarSign} text={`${product.price.toFixed(2)}`} />
                <ProductDetail icon={faWeight} text={`${product.weight} kg`} />
                <ProductDetail icon={faTag} text={product.type} />
                <ProductDetail icon={faBone} text={product.dietForm} />
                <ProductDetail icon={faBarcode} text={product.batchNo} />
                <ProductDetail icon={faCalendarAlt} text={`MFG-${product.manufacturingDate}`} />
                <ProductDetail icon={faCalendarAlt} text={`EXP-${product.expiryDate}`} />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2 w-100 cart-actions">
                <ActionButton onClick={handleRemoveFromCart}>
                  <FontAwesomeIcon icon={faMinus} />
                </ActionButton>
                <span className="mx-2" style={{ fontSize: '17px' }}>{cartCount}</span>
                <ActionButton onClick={handleAddToCart}>
                  <FontAwesomeIcon icon={faPlus} />
                </ActionButton>
                <CartButton onClick={handleAddToCart} className="ml-2">
                  <FontAwesomeIcon icon={faCartShopping} className="pl-2" />
                </CartButton>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showQRCodeModal} onHide={handleCloseQRCodeModal} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="text-center" style={{ fontSize: '1.3rem' }}>
            Scan QR to Verify Blockchain Trust Certificate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <QRCode value={product.cert} size={256} level="H" includeMargin />
        </Modal.Body>
      </Modal>

      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="text-right w-100">
            <FontAwesomeIcon
              onClick={handleCloseImageModal}
              style={{ cursor: 'pointer', color: 'black' }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <img
            src={productImage}
            alt={product.name}
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ProductDetail = ({ icon, text }) => (
  <div className="product-detail" style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
    <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />
    <span>{text}</span>
  </div>
);

export default ProductQR;
