import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../images/bg-login.png';
import companyLogo from '../images/bg-rc.png';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      width: '100vw',
      padding: '20px',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    formContainer: {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      padding: '50px',
      borderRadius: '12px',
      boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
      width: '500px',
      position: 'relative',  // Added for logo positioning
    },
    logo: {
      width: '150px',
      height: '52px',
      objectFit: 'cover',
      position: 'absolute',
      top: '6%',  // Half outside the form
      left: '50%',
      transform: 'translateX(-50%)',
    },
    title: {
      textAlign: 'center',
      marginBottom: '30px',
      fontSize: '28px',
      fontWeight: '600',
      paddingTop: '50px',  // Give space for the logo
      color: '#555',
    },
    inputStyle: {
      display: 'block',
      width: '100%',
      marginBottom: '20px',
      padding: '15px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      fontSize: '18px',
      boxSizing: 'border-box'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '30px',
    },
    submitButton: {
      padding: '12px 25px',
      fontSize: '20px',
      backgroundColor: 'red',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.3s',
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (role === '') {
      alert("Please select a role.");
    } else if (role === 'admin' && username === 'admin' && password === 'admin123') {
      navigate('/AdminDashboard');
    } else if (role === 'customer' && username === 'customer' && password === 'customer123') {
      navigate('/CustomerDashboard');
    } else {
      alert("Invalid credentials or role mismatch. Please try again.");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <img
          src={companyLogo}  // Use the actual logo image
          alt="Company Logo"
          style={styles.logo}
        />
        <h1 style={styles.title}>Login</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder='Enter username'
            value={username}
            style={styles.inputStyle}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder='Enter password'
            value={password}
            style={styles.inputStyle}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <select
            value={role}
            style={styles.inputStyle}
            onChange={(e) => setRole(e.target.value)}
            required>
            <option value="">Select Role</option>
            <option value="admin">Admin</option>
            <option value="customer">Customer</option>
          </select>
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.submitButton}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
