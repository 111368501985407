import React, { useState, useEffect } from 'react';
import "../App.css";

const InsertProductForm = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [productType, setProductType] = useState('');
  const [productName, setProductName] = useState('');
  const [dietForm, setDietForm] = useState('');

  const dogFoodOptions = [
    "Royal Canin Maxi Puppy", "Royal Canin Giant Puppy", "Royal Canin Shih Tzu Adult",
    "Royal Canin Mini Puppy", "Royal Canin Puppy", "Royal Canin Maxi Starter"
  ];

  const catFoodOptions = [
    "Royal Canin Kitten", "Royal Canin Fit 32", "Royal Canin Persian Adult",
    "Royal Canin Indoor 27", "Royal Canin Kitten Gravy", "Royal Canin Hairball Care"
  ];

  useEffect(() => {
    setErrors({});
    setProductType('');
    setProductName('');
    setDietForm('');
  }, []);

  const validateDates = (manufacturingDate, expiryDate) => {
    if (new Date(expiryDate) <= new Date(manufacturingDate)) {
      return "Expiry date must be later than the manufacturing date.";
    }
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const {
      dietForm, manufacturingDate, expiryDate, productPrice, productWeight,
      productBatchNo, productImage, certificateOfTrust
    } = form.elements;

    const dateError = validateDates(manufacturingDate.value, expiryDate.value);
    const newErrors = {};
    if (dateError) {
      newErrors.expiryDate = dateError;
    }

    Array.from(form.elements).forEach(input => {
      if (!input.validity.valid) {
        newErrors[input.name] = input.validationMessage || 'Invalid input';
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('image', productImage.files[0]);
    formData.append('certificate', certificateOfTrust.files[0]);

    try {
      const uploadResponse = await fetch('http://74.225.226.123:8080/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload files');
      }

      const fileDetails = await uploadResponse.json();

      const apiPayload = {
        name: productName,
        price: parseFloat(productPrice.value),
        weight: parseInt(productWeight.value, 10),
        dietForm: dietForm.value,
        batchNo: productBatchNo.value,
        type: productType,
        md: manufacturingDate.value,
        ed: expiryDate.value,
        imageurl: fileDetails.files.imageURL,
        certurl: fileDetails.files.certificateURL
      };

      const response = await fetch('http://74.225.226.123:8080/api/CreateProduct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(apiPayload)
      });

      if (!response.ok) throw new Error('Failed to create product');

      setLoadingStep(3);
      setTimeout(() => {
        setLoadingStep(4);
        setShowPopup(false);
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.error("Error:", error);
      setErrors({ form: 'Failed to create product.' });
      setShowPopup(false);
    }
  };

  const startLoading = (event) => {
    event.preventDefault();
    setShowPopup(true);
    setLoadingStep(0);
    setTimeout(() => setLoadingStep(1), 3000);
    setTimeout(() => setLoadingStep(2), 6000);
    setTimeout(() => handleSubmit(event), 6000);
  };

  const handleTypeChange = (event) => {
    setProductType(event.target.value);
    setProductName('');
  };

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const getProductNameOptions = () => {
    return productType === "Dog Food" ? dogFoodOptions : catFoodOptions;
  };

  return (
    <div className="insert-product-container" style={{ marginTop: '0.2rem' }}>
      <h3 className="text-center mb-1 font-weight-bold" style={{ fontSize: '1.6rem' }}>Insert Product</h3>
      <form onSubmit={startLoading} encType="multipart/form-data">
        <div className="row mb-1">
          <div className="col-md-6 mb-1">
            <label htmlFor="productType" className="form-label">Type</label>
            <select
              className="form-control form-control-md"
              id="productType"
              name="productType"
              value={productType}
              onChange={handleTypeChange}
              required
            >
              <option value="">Select Type</option>
              <option value="Dog Food">Dog Food</option>
              <option value="Cat Food">Cat Food</option>
            </select>
            {errors.productType && <div className="text-danger">{errors.productType}</div>}
          </div>
          <div className="col-md-6 mb-1">
            <label htmlFor="productName" className="form-label">Name</label>
            <select
              className="form-control form-control-md"
              id="productName"
              name="productName"
              value={productName}
              onChange={handleNameChange}
              required
            >
              <option value="">Select Name</option>
              {productType && getProductNameOptions().map(name => (
                <option key={name} value={name}>{name}</option>
              ))}
            </select>
            {errors.productName && <div className="text-danger">{errors.productName}</div>}
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-md-6 mb-1">
            <label htmlFor="productPrice" className="form-label">Price ($)</label>
            <input
              type="number"
              className="form-control form-control-md"
              id="productPrice"
              name="productPrice"
              placeholder="E.g., 10.99"
              step="0.01"
              min="0"
              required
            />
            {errors.productPrice && <div className="text-danger">{errors.productPrice}</div>}
          </div>
          <div className="col-md-6 mb-1">
            <label htmlFor="productWeight" className="form-label">Weight (kg)</label>
            <select
              className="form-control form-control-md"
              id="productWeight"
              name="productWeight"
              required
            >
              <option value="">Select Weight</option>
              {[1, 2, 4, 10, 12].map(weight => (
                <option key={weight} value={weight}>{weight}</option>
              ))}
            </select>
            {errors.productWeight && <div className="text-danger">{errors.productWeight}</div>}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 mb-1">
            <label htmlFor="dietForm" className="form-label">Diet Form</label>
            <select
              className="form-control form-control-md"
              id="dietForm"
              name="dietForm"
              value={dietForm}
              onChange={(e) => setDietForm(e.target.value)}
              required
            >
              <option value="">Select Diet Form</option>
              <option value="Dry">Dry Food</option>
              <option value="Wet">Wet Food</option>
            </select>
            {errors.dietForm && <div className="text-danger">{errors.dietForm}</div>}
          </div>
          <div className="col-md-6 mb-1">
            <label htmlFor="productBatchNo" className="form-label">Batch Number</label>
            <input
              type="text"
              className="form-control form-control-md"
              id="productBatchNo"
              name="productBatchNo"
              placeholder="E.g., 10001"
              required
            />
            {errors.productBatchNo && <div className="text-danger">{errors.productBatchNo}</div>}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 mb-1">
            <label htmlFor="manufacturingDate" className="form-label">Manufacturing Date</label>
            <input
              type="date"
              className="form-control form-control-md"
              id="manufacturingDate"
              name="manufacturingDate"
              required
            />
            {errors.manufacturingDate && <div className="text-danger">{errors.manufacturingDate}</div>}
          </div>
          <div className="col-md-6 mb-1">
            <label htmlFor="expiryDate" className="form-label">Expiry Date</label>
            <input
              type="date"
              className="form-control form-control-md"
              id="expiryDate"
              name="expiryDate"
              required
            />
            {errors.expiryDate && <div className="text-danger">{errors.expiryDate}</div>}
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-md-6 mb-1">
            <label htmlFor="productImage" className="form-label">Product Image (JPG or PNG)</label>
            <input
              type="file"
              className="form-control form-control-md"
              id="productImage"
              name="productImage"
              accept="image/jpeg, image/png"
              required
            />
            {errors.productImage && <div className="text-danger">{errors.productImage}</div>}
          </div>
          <div className="col-md-6 mb-1">
            <label htmlFor="certificateOfTrust" className="form-label">Trust Certificate (PDF)</label>
            <input
              type="file"
              className="form-control form-control-md"
              id="certificateOfTrust"
              name="certificateOfTrust"
              accept="application/pdf"
              required
            />
            {errors.certificateOfTrust && <div className="text-danger">{errors.certificateOfTrust}</div>}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-1">
          <button type="submit" className="btn btn-danger btn-lg">Submit</button>
        </div>
      </form>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div className="loading-step">
              {loadingStep >= 0 && (
                <div className="loading-item">
                  <span>Validating Product Data</span>
                  {loadingStep >= 1 ? <span className="tick"> ✔</span> : <span className="spinner">⏳</span>}
                </div>
              )}
              {loadingStep >= 1 && (
                <div className="loading-item">
                  <span>Adding Product Data on Blockchain</span>
                  {loadingStep >= 2 ? <span className="tick"> ✔</span> : <span className="spinner">⏳</span>}
                </div>
              )}
              {loadingStep >= 2 && (
                <div className="loading-item">
                  <span>Product Created Successfully</span>
                  {loadingStep >= 3 ? <span className="tick"> ✔</span> : <span className="spinner">⏳</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default InsertProductForm;
